import { request } from '@dcyjs/network'


/**
 * InstancePageQuery
 */
export interface InstancePageParams {
  /**
   * 课程名称模糊查询
   */
  courseInstanceNameLike?: string;
  /**
   * 创建人id
   */
  createdBy?: string;
  /**
   * 查询创建时间范围下限
   */
  createdTimeFrom?: Date;
  /**
   * 查询创建时间范围上限
   */
  createdTimeTo?: Date;
  /**
   * 院系id
   */
  departmentId?: string;
  /**
   * 是否启用
   */
  enabled?: boolean;
  /**
   * 主键id
   */
  id?: string;
  /**
   * 主键id集合
   */
  idList?: string[];
  /**
   * 专业id
   */
  majorId?: string;
  /**
   * 排序字段
   */
  orderBy?: string;
  /**
   * 排序方式 asc/desc
   */
  orderType?: string;
  /**
   * 归属组织id;根据账号类型存储不同组织id，学校ID、公司ID
   */
  organizationId?: string;
  /**
   * 页码
   */
  page?: number;
  /**
   * 是否公开
   */
  share?: boolean;
  /**
   * 每页条数
   */
  size?: number;
  /**
   * 开课教师id
   */
  teacherId?: string;
  /**
   * 更新人id
   */
  updatedBy?: string;
  /**
   * 查询更新时间范围下限
   */
  updatedTimeFrom?: Date;
  /**
   * 查询更新时间范围上限
   */
  updatedTimeTo?: Date;
  [property: string]: any;
}

/**
 * PageBean«InstancePageVO»
 */
export interface PageBeanInstancePageVO {
  content?: InstancePageVO[];
  number?: number;
  numberOfElements?: number;
  size?: number;
  totalElements?: number;
  totalPages?: number;
  [property: string]: any;
}

/**
* InstancePageVO
*/
export interface InstancePageVO {
  /**
   * 应用ID
   */
  appId?: string;
  /**
   * 被引用次数
   */
  beUsedNum?: number;
  /**
   * 课程名称
   */
  courseInstanceName?: string;
  /**
   * 课程封面文件id
   */
  coverFileId?: string;
  /**
   * 课程封面文件id
   */
  coverFileUrl?: string;
  /**
   * 创建人id
   */
  createdBy?: string;
  /**
   * 创建人名称
   */
  createdByName?: string;
  /**
   * 创建时间
   */
  createdTime?: Date;
  /**
   * 是否启用
   */
  enabled?: boolean;
  /**
   * 引导课程实例完成步骤数
   */
  guideNum?: number;
  /**
   * 主键id
   */
  id?: string;
  /**
   * 专业id
   */
  majorId?: string;
  /**
   * 专业名称
   */
  majorName?: string;
  /**
   * 院系名称
   */
  rangeName?: string;
  /**
   * 备注
   */
  remark?: string;
  /**
   * 学校id
   */
  schoolId?: string;
  /**
   * 是否公开
   */
  share?: boolean;
  /**
   * 归档状态;1-未归档，2-已归档
   */
  status?: number;
  /**
   * 开课教师id
   */
  teacherId?: string;
  /**
   * 开课教师名字
   */
  teacherName?: string;
  /**
   * 更新人id
   */
  updatedBy?: string;
  /**
   * 更新人名称
   */
  updatedByName?: string;
  /**
   * 更新时间
   */
  updatedTime?: Date;
  [property: string]: any;
}


/**
 * InstanceUpdateEnabledReqOpenDTO
 */
export interface InstanceUpdateEnabledParams {
  /**
   * 应用ID
   */
  appId?: string;
  /**
   * 禁用标识
   */
  enabled: boolean;
  /**
   * 主键id
   */
  id: string;
  [property: string]: any;
}

/**
 * InstanceUpdateShareReqOpenDTO
 */
export interface InstanceUpdateShareParams {
  /**
   * 应用ID
   */
  appId?: string;
  /**
   * 主键id
   */
  id: string;
  /**
   * 公开标识
   */
  share: boolean;
  [property: string]: any;
}


/**
 * InstancePlatformCreateReqOpenDTO
 */
export interface InstancePlatformCreateParams {
  /**
   * 应用ID
   */
  appId?: string;
  /**
   * 课程实例id
   */
  courseInstanceId?: string;
  /**
   * 课程实例名称
   */
  courseInstanceName?: string;
  /**
   * 封面id
   */
  coverFileId?: string;
  /**
   * 专业id
   */
  majorId?: string;
  [property: string]: any;
}

/**
 * InstancePlatformPageQuery
 */
export interface InstancePlatformPageParams {
  /**
   * 课程名称模糊查询
   */
  courseInstanceNameLike?: string;
  /**
   * 创建人id
   */
  createdBy?: string;
  /**
   * 查询创建时间范围下限
   */
  createdTimeFrom?: Date;
  /**
   * 查询创建时间范围上限
   */
  createdTimeTo?: Date;
  /**
   * 主键id
   */
  id?: string;
  /**
   * 主键id集合
   */
  idList?: string[];
  /**
   * 专业id
   */
  majorId?: string;
  /**
   * 排序字段
   */
  orderBy?: string;
  /**
   * 排序方式 asc/desc
   */
  orderType?: string;
  /**
   * 页码
   */
  page?: number;
  /**
   * 是否发布
   */
  publish?: boolean;
  /**
   * 每页条数
   */
  size?: number;
  /**
   * 更新人id
   */
  updatedBy?: string;
  /**
   * 查询更新时间范围下限
   */
  updatedTimeFrom?: Date;
  /**
   * 查询更新时间范围上限
   */
  updatedTimeTo?: Date;
  [property: string]: any;
}

/**
 * PageBean«InstancePlatformPageVO»
 */
export interface PageBeanInstancePlatformPageVO {
  content?: InstancePlatformPageVO[];
  number?: number;
  numberOfElements?: number;
  size?: number;
  totalElements?: number;
  totalPages?: number;
  [property: string]: any;
}

/**
* InstancePlatformPageVO
*/
export interface InstancePlatformPageVO {
  /**
   * 应用ID
   */
  appId?: string;
  /**
   * 被引用次数
   */
  beUsedNum?: number;
  /**
   * 课程名称
   */
  courseInstanceName?: string;
  /**
   * 课程封面文件id
   */
  coverFileId?: string;
  /**
   * 课程封面文件id
   */
  coverFileUrl?: string;
  /**
   * 创建人id
   */
  createdBy?: string;
  /**
   * 创建人名称
   */
  createdByName?: string;
  /**
   * 创建时间
   */
  createdTime?: Date;
  /**
   * 主键id
   */
  id?: string;
  /**
   * 专业id
   */
  majorId?: string;
  /**
   * 专业名称
   */
  majorName?: string;
  /**
   * 是否发布
   */
  publish?: boolean;
  /**
   * 平台关联课程实例id
   */
  relationId?: string;
  /**
   * 备注
   */
  remark?: string;
  /**
   * 源课程实例id
   */
  sourceId?: string;
  /**
   * 源课程实例组织id
   */
  sourceOrganizationId?: string;
  /**
   * 更新人id
   */
  updatedBy?: string;
  /**
   * 更新人名称
   */
  updatedByName?: string;
  /**
   * 更新时间
   */
  updatedTime?: Date;
  [property: string]: any;
}


/**
 * InstancePlatformUpdateReqOpenDTO
 */
export interface InstancePlatformUpdateParams {
  /**
   * 应用ID
   */
  appId?: string;
  /**
   * 课程实例名称
   */
  courseInstanceName?: string;
  /**
   * 封面id
   */
  coverFileId?: string;
  /**
   * 主键id
   */
  id?: string;
  /**
   * 专业id
   */
  majorId?: string;
  /**
   * 发布范围
   */
  organizationIdList?: string[];
  /**
   * 是否发布
   */
  publish?: boolean;
  [property: string]: any;
}

/**
 * InstancePlatformPublishReqOpenDTO
 */
export interface InstancePlatformPublishParams {
  /**
   * 应用ID
   */
  appId?: number;
  /**
   * 平台收纳课程id
   */
  idList: number[];
  /**
   * 发布范围
   */
  organizationIdList: number[];
  [property: string]: any;
}
/**
 * BaseSuccessRespDTO
 */
export interface BaseSuccessRespDTO {
  /**
   * 应用ID
   */
  appId?: string;
  /**
   * 是否成功
   */
  isSuccess?: boolean;
  [property: string]: any;
}

/**
 * Payload«InstancePlatformDetailVO»
 */
export interface Response {
  code?: string;
  msg?: string;
  payload?: InstancePlatformDetailVO;
  [property: string]: any;
}

/**
* InstancePlatformDetailVO
*/
export interface InstancePlatformDetailVO {
  /**
   * 应用ID
   */
  appId?: string;
  /**
   * 被引用次数
   */
  beUsedNum?: number;
  /**
   * 课程名称
   */
  courseInstanceName?: string;
  /**
   * 课程封面文件id
   */
  coverFileId?: string;
  /**
   * 课程封面文件id
   */
  coverFileUrl?: string;
  /**
   * 创建人id
   */
  createdBy?: string;
  /**
   * 创建人名称
   */
  createdByName?: string;
  /**
   * 创建时间
   */
  createdTime?: Date;
  /**
   * 主键id
   */
  id?: string;
  /**
   * 专业id
   */
  majorId?: string;
  /**
   * 专业名称
   */
  majorName?: string;
  /**
   * 是否发布
   */
  publish?: boolean;
  /**
   * 发布组织信息
   */
  publishOrganizationList?: PublishOrgDTO[];
  /**
   * 平台关联课程实例id
   */
  relationId?: string;
  /**
   * 备注
   */
  remark?: string;
  /**
   * 源课程实例id
   */
  sourceId?: string;
  /**
   * 源课程实例组织id
   */
  sourceOrganizationId?: string;
  /**
   * 更新人id
   */
  updatedBy?: string;
  /**
   * 更新人名称
   */
  updatedByName?: string;
  /**
   * 更新时间
   */
  updatedTime?: Date;
  [property: string]: any;
}

/**
* PublishOrgDTO
*/
export interface PublishOrgDTO {
  /**
   * 组织id
   */
  organizationId?: string;
  /**
   * 组织名称
   */
  organizationName?: string;
  [property: string]: any;
}


/**
 * 课程实例管理分页查询(校本资源 组织资源)
 * @param data
 * @returns
 */
export function getInstancePageReq(data: InstancePageParams) {
  return request<PageBeanInstancePageVO>('/course/instance/page', data)
}


/**
 * 课程实例管理启用/禁用(校本资源)
 * @param data
 * @returns
 */
export function instanceUpdateEnabledReq(data: InstanceUpdateEnabledParams) {
  return request<BaseSuccessRespDTO>('/course/instance/updateEnabled', data)
}


/**
 * 课程实例管理设置公开/不公开(校本资源)
 * @param data
 * @returns
 */
export function instanceUpdateShareReq(data: InstanceUpdateShareParams) {
  return request<BaseSuccessRespDTO>('/course/instance/updateShare', data)
}



/**
 * 创建平台课程资源(创建精品课程、收纳资源)
 * @param data
 * @returns
 */
export function createPlatformInstanceReq(data: InstancePlatformCreateParams) {
  return request<BaseSuccessRespDTO>('/course/instance/platform/create', data)
}

/**
 * 平台课程资源分页查询(精品课程)
 * @param data
 * @returns
 */
export function getPlatformInstancePageReq(data: InstancePlatformPageParams) {
  return request<PageBeanInstancePlatformPageVO>('/course/instance/platform/page', data)
}

/**
 * 精品课程课程详情
 * @param data
 * @returns
 */
export function detailPlatformInstanceReq(data: {id?: string}) {
  return request<InstancePlatformDetailVO>(`/course/instance/platform/detail/${data?.id}`)
}

/**
 * 编辑平台课程资源(编辑精品课程)
 * @param data
 * @returns
 */
export function updatePlatformInstanceReq(data: InstancePlatformUpdateParams) {
  return request<BaseSuccessRespDTO>('/course/instance/platform/update', data)
}

/**
 * 发布平台课程资源
 * @param data
 * @returns
 */
export function publishPlatformInstanceReq(data: InstancePlatformPublishParams) {
  return request<BaseSuccessRespDTO>('/course/instance/platform/publish', data)
}

/**
 * 取消发布发布平台课程资源
 * @param data
 * @returns
 */
export function unPublishPlatformInstanceReq(data: {id?: string}) {
  return request<BaseSuccessRespDTO>(`/course/instance/platform/unPublish/${data?.id}`)
}

/**
 * 删除平台课程资源
 * @param data
 * @returns
 */
export function delPlatformInstanceReq(data: {id?: string}) {
  return request<BaseSuccessRespDTO>(`/course/instance/platform/delete/${data?.id}`)
}

/**
 * 课程实例详情
 * @param data
 * @returns
 */
export function getCourseInstanceDetailReq(data: {id: string}) {
  return request<PageBeanInstancePageVO>(`/course/instance/detail/${data.id}`)
}

/**
 * 课程目标列表
 * @param data
 * @returns
 */
export function getCourseInstanceTargetListReq(data: {courseInstanceId: string}) {
  return request<PageBeanInstancePageVO>(`/course/target/list`, data)
}

/**
 * 课程班级列表
 * @param data
 * @returns
 */
export function getCourseInstanceClassListReq(data: {courseInstanceId: string}) {
  return request<PageBeanInstancePageVO>(`/course/instance/class/list`, data)
}


/**
 * 课程班级列表
 * @param data
 * @returns
 */
export function getCourseInstanceClassMemberListReq(data: {classId: string}) {
  return request<PageBeanInstancePageVO>(`/course/instance/class/classMemberList`, data)
}
